<template>
  <div
    class="bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"
  >
    <div class="flex_1 font_16 font_bold">省长占比</div>

    <!-- 图表 -->
    <Chart
      v-if="!loading"
      class="pos_rel z_0 h_64_vw m_t_8"
      :options="options"
    />
    <div v-else class="dis_flex ju_con_cen">
      <van-loading type="spinner" />
    </div>

    <!--  -->
  </div>
</template>

<script>
import { brokerDashboardAppRegisterGovernorProportionPost } from '@/api/index';
import { columnAmount } from '../../../utils/tools';

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
  },
  data() {
    return {
      loading: false,
      options: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.getTrendChart();

    //
  },
  methods: {
    // 获取 进件走势图
    async getTrendChart() {
      this.loading = true;

      const data = await brokerDashboardAppRegisterGovernorProportionPost();

      this.loading = false;

      this.options = {
        /* legend: {
          top: 'center',
          right: '0',
          orient: 'vertical',
        }, */
        tooltip: {
          axisPointer: {
            type: 'shadow',
          },
          confine: true,
          formatter: (o) => {
            return [
              `${o.marker}${o.name}`,
              `数量：${o.value}`,
              `占比：${columnAmount(data[o.dataIndex].proportion * 100)}%`,
            ].join('<br />');
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            /* radius: ['20%', '56%'],
            center: ['30%', '50%'], */
            radius: ['32%', '56%'],
            center: ['50%', '50%'],
            // roseType: 'area',
            label: {
              show: true,
              /* color: '#fff',
              position: 'inside', */
            },
            data: data.map((item) => {
              return {
                ...item,
                name: item.brokerName,
                value: item.reigsterCount,
              };
            }),
          },
        ],
      };

      //
    },

    //
  },
};
</script>
